import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Link as LinkScroll} from 'react-scroll';

import LogoSource from '../img/Logo5.png';
import './Logo.scss';

class Logo extends Component {
  render() {
    return (
      <div className="Logomenu" id="TopPage">
        <ul>
        <li id="LogoContainer">
          <Link to="/"><img src={LogoSource} alt="Logo" object-fit="cover" width="100%"/></Link>
        </li>
          <li className="Dropmenu">
          <Link to="/who">Chi siamo</Link>
          </li>
          <li className="Dropmenu">
            <LinkScroll to="ServiceScroll" spy={true} smooth={true}>Servizi</LinkScroll>
          </li>
          <li className="Dropmenu">
          <Link to="/products">Catalogo</Link>
          </li>
          <li className="Dropmenu">
          <LinkScroll to="ContactsScroll" spy={true} smooth={true}>Contatti</LinkScroll>
          </li>
        </ul>
      </div>
    );
  }
}

export default Logo;
