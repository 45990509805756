import React from 'react';
import {Link} from 'react-scroll';
import {IoIosArrowDown} from 'react-icons/io';
import {AiOutlineHome} from 'react-icons/ai';
import {IoMdArrowRoundUp} from "react-icons/io";
import {BsBuilding} from "react-icons/bs";
import {BsBriefcase} from "react-icons/bs";
import '../../Assets/scss/service.scss';
import * as datas from '../../components/data/lists';

class Service extends React.Component {

  state = { isActive1: false };

  state = { isActive2: false };

  state = { isActive3: false };

    handleToggle1 = () => {
      this.setState({ isActive1: !this.state.isActive1 });
      this.setState({ isActive2: false });
      this.setState({ isActive3: false });
    };

    handleToggle2 = () => {
      this.setState({ isActive2: !this.state.isActive2 });
      this.setState({ isActive1: false });
      this.setState({ isActive3: false });
    };

    handleToggle3 = () => {
      this.setState({ isActive3: !this.state.isActive3 });
      this.setState({ isActive1: false });
      this.setState({ isActive2: false });
    };


  render() {
    const isActive1 = this.state.isActive1;
    const isActive2 = this.state.isActive2;
    const isActive3 = this.state.isActive3;
    return (
      <div id="ServiceScroll" className="LaterContent">
          <div className="SectionContent">
          <div className={isActive1||isActive2||isActive3 ? "TitleContentHide" : "TitleContent"}>
            <p>
              SERVIZI
            </p>
            <Link to="TopPage" spy={true} smooth={true}><span><IoMdArrowRoundUp /></span></Link>

          </div>
            <div className="ServiceContentBox">
              <div className={isActive1 ? "ServiceBoxActive" : (isActive2||isActive3 ? "ServiceBoxExt" : "ServiceBox")}  onClick={this.handleToggle1}>
                <span className="ServiceIcon"><AiOutlineHome /></span>
                <p>
                  Privati<span><IoIosArrowDown /></span>
                </p>
              </div>
              <div className={isActive2 ? "ServiceBoxActive" : (isActive1||isActive3 ? "ServiceBoxExt" : "ServiceBox")}  onClick={this.handleToggle2}>
                <span className="ServiceIcon"><BsBriefcase /></span>
                <p>
                  Pubblica Amministrazione<span><IoIosArrowDown /></span>
                </p>
              </div>
              <div className={isActive3 ? "ServiceBoxActive" : (isActive1||isActive2 ? "ServiceBoxExt" : "ServiceBox")}  onClick={this.handleToggle3}>
                <span className="ServiceIcon"><BsBuilding /></span>
                <p>
                    Aziende<span><IoIosArrowDown /></span>
                </p>
              </div>
              <div className={isActive1||isActive2||isActive3 ? "ServiceLeftShow" : "ServiceLeftHide"}>
                <p className={isActive1 ? "ServiceBoxTextShow" : "ServiceBoxTextHide"} style={{textAlign: "left"}}>
                  I nostri servizi per i clienti privati includono:
                </p>
                <p className={isActive1 ? "ServiceBoxTextShow" : "ServiceBoxTextHide"}>
                    <ul>
                      { datas.Privatilistitems.map(Privatilistitem => (
                        <li key={Privatilistitem.id}>
                          {Privatilistitem.value}
                        </li>
                      ))}
                    </ul>
                </p>
              <p className={isActive2 ? "ServiceBoxTextShow" : "ServiceBoxTextHide"} style={{textAlign: "left"}}>
                I nostri servizi per le pubbliche amministrazioni includono:
              </p>
              <p className={isActive2 ? "ServiceBoxTextShow" : "ServiceBoxTextHide"}>
                <ul>
                  { datas.Palistitems.map(Palistitem => (
                    <li key={Palistitem.id}>
                      {Palistitem.value}
                    </li>
                  ))}
                </ul>
              </p>
              <p className={isActive3 ? "ServiceBoxTextShow" : "ServiceBoxTextHide"} style={{textAlign: "left"}}>
                I nostri servizi per le Aziende includono:
              </p>
              <p className={isActive3 ? "ServiceBoxTextShow" : "ServiceBoxTextHide"}>
                <ul>
                  {datas.Azlistitems.map(Azlistitem => (
                    <li key={Azlistitem.id}>
                      {Azlistitem.value}
                    </li>
                  ))}
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Service;
