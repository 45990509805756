import React from 'react';
import {Link} from 'react-scroll';
import {RiMapPinLine} from "react-icons/ri";
import {AiOutlineMail} from "react-icons/ai";
import {IoMdArrowRoundUp} from "react-icons/io";
import {FaPhoneAlt} from "react-icons/fa";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';


const containerStyle = {
  width: '42%',
  height: '50%',
  float: 'right',
  borderRadius: '10px',
  margin: '0 1.5vw 0 0'

};

const center = {
  lat: 45.18443610954912,
  lng: 9.145664370800835
};

class Contacts extends React.Component {

  render() {

    return (
        <div id="ContactsScroll" className="LaterContent">
        <div className="SectionContent">
          <div className="TitleContent">
            <p>
              CONTATTI
            </p>
            <Link to="TopPage" spy={true} smooth={true}><span><IoMdArrowRoundUp /></span></Link>

          </div>
          <div className="S1Content">
          <p> info@H2Informatica.it </p><span><AiOutlineMail /></span>
            <p>Via Giacomo Franchi, 2C, 27100 Pavia PV </p><span> <RiMapPinLine /> </span>
            <p> 0382 / 301160 </p> <span><FaPhoneAlt /></span>

          </div>
          <LoadScript
        googleMapsApiKey="AIzaSyA_SlRYU2ZEOpDV4auDul7zkNhWmPkHmUc"
      >
          <a href="https://goo.gl/maps/cBVcKTTbzPJoShD56" target="_blank" rel="noreferrer noopener">
          <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={16}
          Marker position={{ lat: 45.18443610954912, lng: 9.145664370800835 }}
        >
        <Marker key="marker_1"
                    position={{
                        lat: 45.18443610954912,
                        lng: 9.145664370800835
                    }}
          />
          <></>
        </GoogleMap></a>
      </LoadScript>

        </div>

      </div>
    );
  }
}

export default Contacts;
