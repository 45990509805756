export const Privatilistitems=[
  {id: 'pr1', value: 'Riparazioni e assistenza' },
  {id: 'pr2', value: 'Assemblaggio PC e configurazioni su misura' },
  {id: 'pr3', value: 'Dote scuola'},
  {id: 'pr4', value: 'Carta del docente'},
  {id: 'pr5', value: 'Configurazione a domicilio'},
  {id: 'pr6', value: 'Corsi di apprendimento su misura'},
  {id: 'pr7', value: 'Attivazione SIM e linea internet con gestore Tiscali'},
  {id: 'pr8', value: 'Consulenza Hardware e Software'},
  {id: 'pr9', value: 'Copisteria varia'},
];

export const Palistitems=[
  {id: 'pa1', value: 'Vendite hardware/software su piattaforma MEPA, SINTEL, regione lombardia'},
  {id: 'pa2', value: 'Contratti per assistenza e gestione parco macchine in essere'},
  {id: 'pa3', value: 'Contratti per Amministratore di rete/sistema/backup dati'},
  {id: 'pa4', value: 'Configurazione e gestione Server'},
  {id: 'pa5', value: 'Progettazione e realizzazione reti LAN e Wi-Fi'},
  {id: 'pa6', value: 'Manutenzione ed installazione software gestionale(Argo, Axios, ecc..)'},
  {id: 'pa7', value: 'Assistenza e configurazione DAD'},
  {id: 'pa8', value: 'Manutenzione e gestione Sistema di posta elettronica'},
  {id: 'pa9', value: 'Consulenza Hardware e Software (windows, office, ecc)'}
];

export const Azlistitems=[
  {id: 'az1', value: 'Vendita hardware/software'},
  {id: 'az2', value: 'Assistenza hardware e software parco macchine in essere'},
  {id: 'az3', value: 'Consulenza e creazione siti web'},
  {id: 'az4', value: 'Configurazione e gestione Server'},
  {id: 'az5', value: 'Progettazione e realizzazione reti LAN e Wi-Fi'},
  {id: 'az6', value: 'Manutenzione ed installazione applicativi software'},
  {id: 'az7', value: 'Manutenzione e gestione Sistema di posta elettronica'},
  {id: 'az8', value: 'Consulenza Hardware e Software (windows, office, ecc)'},
  {id: 'az9', value: 'Consulenza e creazione siti web'},
];
