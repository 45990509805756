import React, { Component } from 'react';
import { HashRouter, Route } from 'react-router-dom';

import Logo from './components/logocomponents/Logo';

import './Assets/css/default.min.css';
import Contacts from './components/pages/contacts';
import Service from './components/pages/service';
const Homepage = React.lazy(() => import('./components/pages/homepage'));
const Products = React.lazy(() => import('./components/pages/products'));
const Who = React.lazy(() => import('./components/pages/who'));




class App extends Component {

  render() {
    return (
<>
        <React.Suspense fallback={<p>Caricamento...</p>}>
        <HashRouter>
        <Logo />
            <Route exact path="/">
              <Homepage />
            </Route>

            <Route exact path="/products">
              <Products />

            </Route>

            <Route exact path="/who">
              <Who />
            </Route>
        </HashRouter>
        </React.Suspense>
        <Service />
        <Contacts />
        </>
  );
  }
}

export default App;
